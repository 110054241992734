/* 
 * Home Controller
 */
function homeController($scope, Form) {
    
    $scope.section = 'home';
    $scope.user = {
        "fullname": null,
        "email": null
    };

    $scope.sendForm = function(ev, data) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var errors = [];
        $scope.errors = [];
        $scope.success = "";

        if(angular.isUndefined(data.fullname) || data.fullname == null || data.fullname == "") {
            errors.push('Escribe tu nombre completo');
        }

        if(angular.isUndefined(data.email) || data.email == null || data.email == "") {
            errors.push('Escribe tu correo electrónico');
        }

        if(!re.test(data.email)) {
            errors.push('El formato del correo electrónico es incorrecto');
        }

        if(errors.length > 0) {
            $scope.errors = errors;
            return false;
        }
        
        $scope.errors = [];

        Form.send(data)
            .then(function(response) {
                $scope.user.fullname = null;
                $scope.user.email = null;
                $scope.success = response.data.data;
                console.log($scope.success);
            }, function(response) {
                $scope.errors.push('Ocurrió un error en el registro');
                console.log(response);
            });
            
    }
}

angular.module('website')
    .controller('HomeController', ["$scope", "Form", homeController]);