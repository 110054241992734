/* 
 * App Controller
 * Controlador que maneja la información de la plantilla principal
 */
function appController($scope, $location, $anchorScroll) {
    
    $scope.year = new Date().getFullYear();
    $scope.menuOpen = false;
    
    $scope.redirectTo = function($event, url) {
        $event.preventDefault();
        window.location = window.location.origin + url;
    }

    $scope.scrollTo = function($event, hash) {
        $event.preventDefault();
        $location.hash(hash);
        $anchorScroll();
    }

    $scope.goToForm = function($event, hash) {
        $event.preventDefault();
        angular.element(document.querySelectorAll('#input-name'))[0].focus();
        $location.hash(hash);
        $anchorScroll();
    }

    $scope.openMenu = function() {
        console.log($scope.menuOpen);
        $scope.menuOpen = !$scope.menuOpen;
    }

}

angular.module('website')
        .controller('AppController', ["$scope", "$location", "$anchorScroll", appController]);
