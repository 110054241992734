function menuFixedDrv($window, $document, $location) {
    var directive = {
        restrict: 'C',
        link: function(scope, element) {

            function shrinkMenu() {
                if($window.scrollY > $window.innerHeight - element[0].clientHeight) {
                    if(element.hasClass('position-absolute')) {
                        element.removeClass('position-absolute');
                        element.addClass('position-fixed');
                    }
                } else {
                    if(element.hasClass('position-fixed')) {
                        element.removeClass('position-fixed');
                        element.addClass('position-absolute');
                    }
                }
            }
            shrinkMenu();

            scope.$on("$routeChangeSuccess", function () {
                $document.off('scroll wheel DOMMouseScroll mousewheel', shrinkMenu);
            });

            $document.on('scroll wheel DOMMouseScroll mousewheel', shrinkMenu);

        }
    }

    return directive;
}

angular.module('website')
    .directive('siteHeader', ["$window", "$document", "$location", menuFixedDrv]);