function routesConf($stateProvider, $urlRouterProvider, $locationProvider) {

    /** Routes **/
    $locationProvider.html5Mode(true).hashPrefix('/');

    /** For unmatched routes **/
    $urlRouterProvider.otherwise('/');

    $stateProvider
        .state('index', {
            views: {
                '': {
                    templateUrl: '/layout'
                },
                'header@index': {
                    templateUrl: '/header'
                },
                'footer@index': {
                    templateUrl: '/footer'
                }
            }
        })
        .state('index.home', {
            url: '/',
            templateUrl: '/home',
            controller: 'HomeController'
        });

}

angular.module('website')
    .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', routesConf]);